import { useState } from 'react';
// Lib
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers5/react';
// @mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
// Icons
import LoginIcon from '@mui/icons-material/Login';
// Components
import Image from './Image';
import TransferPopover from './BankTransferPopover';
import CryptoTransferPopover from './CryptoTransferPopover';
// Models

import { initWeb3Modal } from '../utils/crypto';
import dayjs from 'dayjs';
// Reward can be made by crypto.
initWeb3Modal();

function createData(serialNumber: string, material: string = 'aluminium') {
  return { serialNumber, material, icon: '/icons/ic_aluminium.svg' };
}

const rows = [
  createData('2211223'),
  createData('4332356'),
  createData('6643568'),
  createData('4335677'),
  createData('4343432'),
];

type Props = {
  detailed?: boolean;
};

type StepType = {
  label: string;
  date?: string;
  name?: string;
};

const steps: Array<StepType> = [
  {
    label: 'Storer',
    date: '12 December 2023',
    name: 'Boardwalk Place Shop',
  },
  {
    label: 'Recycler',
    date: '14 December 2023',
    name: 'W Recycling LTD',
  },
  {
    label: 'Recycled',
  },
];

type TransferType = {
  id: string;
  label: string;
  icon?: any;
};

const selectOptions: Array<TransferType> = [
  {
    id: 'bank',
    label: 'Bank transfer',
    icon: '/icons/ic_banking.svg',
  },
  {
    id: 'plp',
    label: 'Paypal',
    icon: '/icons/ic_paypal.svg',
  },
  {
    id: 'wise',
    label: 'Wise',
    icon: '/icons/ic_wise.svg',
  },
  {
    id: 'usdc',
    label: 'USDC',
    icon: '/icons/ic_usdc.svg',
  },
  {
    id: 'usdt',
    label: 'USDT',
    icon: '/icons/ic_usdt.svg',
  },
];

export default function Reward({ details }: any) {
  // Web3 hooks.
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const web3Modal = useWeb3Modal();
  //state
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>('bank');

  const claimButton = () => {
    if (['usdc', 'usdt'].includes(paymentMethod)) {
      return isConnected ? (
        <Button
          size="large"
          variant="contained"
          fullWidth={true}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setPopoverAnchorEl(event.currentTarget);
          }}
        >
          Claim gas free reward
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          fullWidth={true}
          onClick={() => web3Modal.open()}
          startIcon={<LoginIcon />}
        >
          Connect Wallet
        </Button>
      );
    }
    return (
      <Button
        size="large"
        variant="contained"
        fullWidth={true}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopoverAnchorEl(event.currentTarget);
        }}
      >
        Request Transfer
      </Button>
    );
  };

  return (
    <>
      <Grid container={true} spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader
              title={`${
                details?.mission_details?.reward_amount * details?.mission_details?.delivered_items
              } | ${details?.mission_details?.mission_title} 300ml`}
              subheader={`${dayjs(
                details?.mission_details?.reward_claimed_at.value,
                'DD-MMMM-YYYY',
              ).format('DD MMMM YYYY')}`}
              avatar={
                <Card sx={{ borderRadius: '100%', height: 50, width: 50 }}>
                  <CardMedia
                    component="img"
                    alt="-"
                    width="100%"
                    height="100%"
                    src={
                      details?.mission_details?.mission_image
                        ? `https://storage.googleapis.com${details?.mission_details?.mission_image}`
                        : '/images/bck_placeholder.jpg'
                    }
                  />
                </Card>
              }
              action={
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    border: '1px solid #efefef',
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  {`Trace & Collect Rewards`}
                </Typography>
              }
            />
            <CardContent>
              <Stepper activeStep={1} alternativeLabel={true}>
                {steps.map((step: StepType, index: number) => (
                  <Step key={index}>
                    <StepLabel>
                      <Typography variant="subtitle2" component="div" paragraph={true}>
                        {step.label}
                      </Typography>
                      {step.name ? (
                        <Typography variant="subtitle1" component="div" paragraph={true}>
                          {step.name}
                        </Typography>
                      ) : (
                        <Chip size="medium" label="Pending" color="warning" />
                      )}
                      <Typography variant="caption" component="div">
                        {step.date}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Rewards per item"
                    id="rewards-per-item"
                    value={details?.mission_details?.reward_amount}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Claimed items"
                    id="claimed-items"
                    value={details?.mission_details?.delivered_items}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Image src="/icons/ic_can.svg" sx={{ width: 26, mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Total"
                    id="claimed-total"
                    value={
                      details?.mission_details?.reward_amount *
                      details?.mission_details?.delivered_items
                    }
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Reward paid by"
                    id="paid-by"
                    select={true}
                    value={paymentMethod}
                    onChange={(event) => setPaymentMethod(event.target.value)}
                  >
                    {selectOptions?.map((optionItem: TransferType) => (
                      <MenuItem key={optionItem.id} value={optionItem.id}>
                        <Grid container spacing={1}>
                          <Grid item={true} xs={12} md={3}>
                            {typeof optionItem?.icon === 'string' ? (
                              <Image src={optionItem?.icon} sx={{ height: 26, mr: 2 }} />
                            ) : (
                              optionItem.icon
                            )}
                          </Grid>
                          <Grid item={true} xs={12} md={9}>
                            {optionItem.label}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  {claimButton()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '80%' }}>
            <CardMedia
              component="img"
              alt="-"
              width="100%"
              height="100%"
              src={
                details?.mission_details?.mission_image
                  ? `https://storage.googleapis.com${details?.mission_details?.mission_image}`
                  : '/images/bck_placeholder.jpg'
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label="Recyclers table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Confirmation QR Code</TableCell>
                  <TableCell align="right" />
                  <TableCell align="right">Material</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details &&
                  details.mission_rewards.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.reward_id.split('-')[0]}
                      </TableCell>
                      <TableCell align="left">
                        <Image
                          src={
                            details?.mission_details?.mission_image
                              ? `https://storage.googleapis.com${details?.mission_details?.mission_image}`
                              : '/images/bck_placeholder.jpg'
                          }
                          sx={{ width: 26, height: 26, mr: 2, borderRadius: '100%' }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Chip
                          size="medium"
                          label={details?.mission_details?.mission_materialType}
                          color="success"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {['usdc', 'usdt'].includes(paymentMethod) ? (
        <CryptoTransferPopover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          token={paymentMethod}
          chainId={chainId}
          address={address}
          closePopup={() => setPopoverAnchorEl(null)}
        />
      ) : (
        <TransferPopover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          paymentMethod={paymentMethod}
          closePopup={() => setPopoverAnchorEl(null)}
        />
      )}
    </>
  );
}
